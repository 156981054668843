import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { darken, lighten } from "polished"

import { Col, Color, Margin, Grid } from "../components/utilities"
import { Banner, Section } from "../components/elements"
import { Layout } from "../components/layouts/Layout"

const Table = styled.table`
  width: 100%;
  border: 1px solid ${lighten(0.2, Color.grey)};
  border-radius: 15px;
  padding: 2rem;

  tr {
    color: ${Color.dark_grey};
    &:nth-child(even) {
      background-color: #f5f5f5;
    }

    td {
      padding: 0.4rem 0.75rem;

      &:nth-child(even) {
        text-align: center;
        font-weight: bold;
        color: ${darken(0.2, Color.pink)};
      }
    }
  }
`

const Services = ({ data }) => {
  const { front } = data
  return (
    <Layout title="Services">
      <Banner title="services" />
      <Section>
        <Grid modifiers={["gap"]}>
          <Col>
            <Table cellPadding="0" cellSpacing="0">
              <h3>Hair Services:</h3>
              <tr>
                <td>Women's Haircut:</td>
                <td>$40 and up</td>
              </tr>

              <tr>
                <td>Men's Haircut:</td>
                <td>$21 and up</td>
              </tr>

              <tr>
                <td>Touch Up:</td>
                <td>$60 and up</td>
              </tr>

              <tr>
                <td>All Over Color:</td>
                <td>$70 and up</td>
              </tr>

              <tr>
                <td>Partial Highlights:</td>
                <td>$90 and up</td>
              </tr>

              <tr>
                <td>Partial Highlight/ Haircut/ Toner:</td>
                <td>$130 and up</td>
              </tr>

              <tr>
                <td>Full Highlight:</td>
                <td>$100 and up</td>
              </tr>

              <tr>
                <td>Full Highlight/ Haircut/ Toner:</td>
                <td>$140 and up</td>
              </tr>

              <tr>
                <td>Balayage:</td>
                <td>$110 and up</td>
              </tr>
              <tr>
                <td>Full Balayage/ Haircut/ Toner:</td>
                <td>$150 and up</td>
              </tr>

              <tr>
                <td>Keratin Blowout:</td>
                <td>$170 and up</td>
              </tr>

              <tr>
                <td>Shampoo Heatstyle:</td>
                <td>$30 and up</td>
              </tr>

              <tr>
                <td>Conditioning Treatment:</td>
                <td>$13 and up</td>
              </tr>
              <tr>
                <td>Gloss:</td>
                <td>$40 and up</td>
              </tr>
              <tr>
                <td>Extensions: </td>
                <td>A consultation is required for this service</td>
              </tr>
            </Table>
          </Col>
          <Col>
            <Table cellPadding="0" cellSpacing="0">
              <h3>Skin Care Services:</h3>
              <tr>
                <td>Signature Facial:</td>
                <td>$85</td>
              </tr>
              <tr>
                <td>Dermaplane Facial:</td>
                <td>$115</td>
              </tr>
              <tr>
                <td>Mini Facial:</td>
                <td>$48 and up</td>
              </tr>
              <tr>
                <td>Chemical Peel:</td>
                <td>$85 and up</td>
              </tr>
              <tr>
                <td>Dermaplane:</td>
                <td>$52 and up</td>
              </tr>
            </Table>
          </Col>
          <Col>
            <Table cellPadding="0" cellSpacing="0">
              <h3>Waxing:</h3>
              <tr>
                <td>Eyebrow Shape:</td>
                <td>$17</td>
              </tr>
              <tr>
                <td>Lip:</td>
                <td>$12</td>
              </tr>
              <tr>
                <td>Lip/Brow:</td>
                <td>$30</td>
              </tr>
              <tr>
                <td>Lip/Brow/Chin:</td>
                <td>$40</td>
              </tr>
              <tr>
                <td>Chin:</td>
                <td>$12</td>
              </tr>
              <tr>
                <td>Nose:</td>
                <td>$10</td>
              </tr>
            </Table>
          </Col>
          <Col>
            <Table cellPadding="0" cellSpacing="0">
              <h3>Tinting Services:</h3>
              <tr>
                <td>Eyebrow Tint:</td>
                <td>$20</td>
              </tr>
              <tr>
                <td>Eyelash Tint:</td>
                <td>$20</td>
              </tr>
              <tr>
                <td>Eyebrow Tint and Shape:</td>
                <td>$35</td>
              </tr>
              <tr>
                <td>Lash and Brow Tint/Wax:</td>
                <td>$50</td>
              </tr>
              <tr>
                <td>Microblading</td>
                <td>$400 and up</td>
              </tr>
            </Table>
          </Col>
          <Col>
            <Table cellPadding="0" cellSpacing="0">
              <h3>Lash Services:</h3>
              <tr>
                <td>Volume Full Set:</td>
                <td>$170</td>
              </tr>
              <tr>
                <td>Classic Full Set:</td>
                <td>$125</td>
              </tr>
              <tr>
                <td>Hybrid Full Set:</td>
                <td>$150</td>
              </tr>
              <tr>
                <td>Fill In:</td>
                <td>$49 and up</td>
              </tr>
              <tr>
                <td>Lash Lift:</td>
                <td>$75</td>
              </tr>
              <tr>
                <td>Lash Lift & Tint:</td>
                <td>$85</td>
              </tr>
            </Table>
          </Col>
        </Grid>
      </Section>
    </Layout>
  )
}

export default Services

export const query = graphql`
  query ServiceQuery {
    front: file(relativePath: { eq: "front.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          src
        }
      }
    }
  }
`
